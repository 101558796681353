import styled from 'styled-components'

import {
  AndroidIcon,
  IosIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  TermsAndConditionsIcon,
  RssIcon,
  HitsIcon,
  TiktokIcon,
  FaqIcon,
} from 'components/AppsIcons/AppsIcons'
import CaretRightIcon from 'assets/images/caret-right-blue.svg'
import ModalBox from 'components/Layout/ModalBox/ModalBox'
import { URLS } from 'shared/constants'

const Ul = styled.ul`
  padding: 0 0.75rem;
  font-size: 1.1rem;
`

const UlTitle = styled.div`
  font-size: 1.1rem;
`

const Hr = styled.div`
  height: 10px;
  margin: 0 0 10px;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-4);
`

const Li = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
  font-size: 1rem;
`

const A = styled.a`
  font: inherit;
  color: var(--color-black);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--color-blue-1);
  }
`

const LiLink = ({ content }) => <li>{content}</li>

const LiText = ({ text }) => (
  <Li>
    <img src={CaretRightIcon} alt="caret-right" />
    {text}
  </Li>
)

const Apps = ({ iconId, onClose }) => (
  <ModalBox iconId={iconId} onClose={onClose}>
    <Ul>
      <LiLink content={<IosIcon withLabel />} />
      <LiLink content={<AndroidIcon withLabel />} />
      <Hr />
      <LiLink content={<FacebookIcon withLabel />} />
      <LiLink content={<InstagramIcon withLabel />} />
      <LiLink content={<TiktokIcon withLabel />} />
      <LiLink content={<TwitterIcon withLabel />} />
      <LiLink content={<WhatsappIcon withLabel />} />
      <Hr />
      <LiLink content={<HitsIcon withLabel />} />
      <LiLink content={<RssIcon withLabel />} />
      <Hr />
      <LiLink content={<EmailIcon withLabel />} />
      <LiLink content={<TermsAndConditionsIcon withLabel />} />
      <Hr />
    </Ul>
    <A href={URLS.FAQ} rel="nofollow noreferrer">
      <Ul>
        <LiLink content={<UlTitle><FaqIcon withLabel /> - Lastminuter to:</UlTitle>} />
        <LiText text="Wycieczki samolotem wielu biur podróży w jednym miejscu." />
        <LiText text="Codziennie nowe znaleziska wyszperane w Internecie." />
        <LiText text="Najszybsza wyszukiwarka." />
        <LiText text="Alerty cenowe, powiadomienia o okazjach." />
      </Ul>
    </A>
  </ModalBox>
)

export default Apps
