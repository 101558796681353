import { URLS } from 'shared/constants'

import AppleImg from 'assets/images/apple.svg'
import AndroidImg from 'assets/images/android.svg'
import EmailImg from 'assets/images/email.svg'
import FaqImg from 'assets/images/faq.svg'
import FacebookImg from 'assets/images/facebook-blue.svg'
import FireFlameImg from 'assets/images/fire-flame.svg'
import InstagramImg from 'assets/images/instagram-blue.svg'
import PrivacyPolicyImg from 'assets/images/privacy-policy.svg'
import RssImg from 'assets/images/rss.svg'
import TwitterImg from 'assets/images/twitter-blue.svg'
import WhatsappImg from 'assets/images/whatsapp-blue.svg'
import TiktokImg from 'assets/images/tiktok-blue.svg'

import './appsIcons.css'

const icons = {
  ios: {
    label: 'Aplikacja na iOS',
    url: URLS.IOS,
    image: AppleImg,
  },
  android: {
    label: 'Aplikacja na Android',
    url: URLS.ANDROID,
    image: AndroidImg,
  },
  instagram: {
    label: 'Lastminuter na Instagramie',
    url: URLS.INSTAGRAM,
    image: InstagramImg,
  },
  facebook: {
    label: 'Lastminuter na Facebooku',
    url: URLS.FACEBOOK,
    image: FacebookImg,
  },
  twitter: {
    label: 'Lastminuter na X',
    url: URLS.TWITTER,
    image: TwitterImg,
  },
  whatsapp: {
    label: 'Lastminuter w WhatsApp',
    url: URLS.WHATSAPP,
    image: WhatsappImg,
  },
  tiktok: {
    label: 'Lastminuter na Tiktoku',
    url: URLS.TIKTOK,
    image: TiktokImg,
  },
  email: {
    label: URLS.EMAIL,
    url: `mailto:${URLS.EMAIL}`,
    image: EmailImg,
  },
  faq: {
    label: 'FAQ',
    url: URLS.FAQ,
    image: FaqImg,
  },
  privacyPolicy: {
    label: 'Regulaminy i Polityka Prywatności',
    url: URLS.ALERTS_TERMS_AND_CONDITIONS,
    image: PrivacyPolicyImg,
  },
  rss: {
    label: 'RSS',
    url: URLS.RSS,
    image: RssImg,
  },
  hits: {
    label: 'Nasze hity',
    url: URLS.HITS,
    image: FireFlameImg,
  },
}

const Icon = ({
  name,
  withLabel = false,
  hoverEffect = false,
  className = 'apps_icon',
  target = '_blank',
}) => (
  <a
    className={className}
    href={icons[name].url}
    target={target}
    rel="nofollow noreferrer"
  >
    <img
      className={hoverEffect ? 'icon_hover_effect' : undefined}
      src={icons[name].image}
      title={icons[name].label}
      alt={icons[name].label}
    />
    {withLabel && icons[name].label}
  </a>
)

const HitsIcon = ({
  className = 'apps_icon',
  withLabel = false,
  hoverEffect = false,
}) => (
  <a className={className} href={icons['hits'].url}>
    <img
      className={hoverEffect ? 'icon_hover_effect' : undefined}
      src={icons['hits'].image}
      title={icons['hits'].label}
      alt={icons['hits'].label}
    />
    {withLabel && icons['hits'].label}
  </a>
)

const AndroidIcon = ({ className, withLabel = false, hoverEffect = false }) => (
  <Icon
    name="android"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const IosIcon = ({ className, withLabel = false, hoverEffect = false }) => (
  <Icon
    name="ios"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const InstagramIcon = ({
  className,
  withLabel = false,
  hoverEffect = false,
}) => (
  <Icon
    name="instagram"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const FacebookIcon = ({
  className,
  withLabel = false,
  hoverEffect = false,
}) => (
  <Icon
    name="facebook"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const TwitterIcon = ({ className, withLabel = false, hoverEffect = false }) => (
  <Icon
    name="twitter"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const WhatsappIcon = ({
  className,
  withLabel = false,
  hoverEffect = false,
}) => (
  <Icon
    name="whatsapp"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const TiktokIcon = ({ className, withLabel = false, hoverEffect = false }) => (
  <Icon
    name="tiktok"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const EmailIcon = ({ className, withLabel = false, hoverEffect = false }) => (
  <Icon
    name="email"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const FaqIcon = ({ className, withLabel = false, hoverEffect = false }) => (
  <Icon
    name="faq"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
    target='_self'
  />
)

const TermsAndConditionsIcon = ({
  className,
  withLabel = false,
  hoverEffect = false,
}) => (
  <Icon
    name="privacyPolicy"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

const RssIcon = ({ className, withLabel = false, hoverEffect = false }) => (
  <Icon
    name="rss"
    className={className}
    withLabel={withLabel}
    hoverEffect={hoverEffect}
  />
)

export {
  AndroidIcon,
  IosIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TiktokIcon,
  EmailIcon,
  FaqIcon,
  TermsAndConditionsIcon,
  RssIcon,
  HitsIcon,
}
